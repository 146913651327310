import { SyntheticEvent, useContext, useMemo, useState } from "react";
import {
  Flex,
  Text,
  Image,
  Drawer,
  Center,
  Divider,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useMediaQuery,
  useDisclosure,
  Button,
  InputGroup,
  Input,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useSwipeable } from "react-swipeable";

import NetworkOptions from "@components/Newsletter/components/NetworkOptions";
import { sheetMapping } from "@components/Newsletter/constants";

import {
  LP_EMAIL_MODAL_OPEN,
  LP_SUBSCRIBE_EMAIL_MODAL,
} from "@constants/firebase";

import useStaderTVL from "hooks/useStaderTVL";

import { PageContext } from "providers/PageProvider";

import { subscribeToEmail } from "services/app";

import {
  prepareNavData,
  setSubscribedToEmail,
  validateEmail,
} from "utils/common";
import { trackEvent } from "@utils/firebase";

const EmailModal = () => {
  const { page } = useContext(PageContext);
  const { tvl } = useStaderTVL();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const { networks } = useMemo(
    () => prepareNavData(page.data.navData, tvl),
    [page.data.navData, tvl]
  );

  const [selectedNetworks, setSelectedNetworks] = useState<{
    [x: string]: boolean;
  }>(() =>
    networks.reduce((acc, curr) => ({ ...acc, [curr.detailId]: true }), {})
  );

  const handleNetworkSelect = (id: string) => {
    setSelectedNetworks((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const [isMobileOrTablet] = useMediaQuery("(max-width: 992px)");

  const handlers = useSwipeable({
    onSwipedDown: () => onClose(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const selectedNetworkList = Object.keys(selectedNetworks).filter(
    (key) => selectedNetworks[key]
  );

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    // trackEvent(SUBSCRIBE_EMAIL, { email_id: email });
    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }

    if (selectedNetworkList.length === 0) {
      setError("No Network selected. Please select atleast one.");
      return;
    }

    setIsSubscribing(true);

    trackEvent(LP_SUBSCRIBE_EMAIL_MODAL, { email_id: email });

    const formData: any = { email, networks: [] };

    selectedNetworkList.forEach((id) => {
      formData.networks.push(sheetMapping[id]);
    });

    const response = await subscribeToEmail(formData);

    if ((response as any).error) {
      toast({
        description: "Failed to subscribe",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsSubscribing(false);

      return;
    }

    setSubscribedToEmail(true);
    onClose();
    toast({
      description: "Email subscribed successfully",
      status: "success",
      duration: 2000,
      position: "top",
      isClosable: true,
    });
    setIsSubscribing(false);
  };

  const unselectedNetworks = Object.keys(selectedNetworks).filter(
    (key) => !selectedNetworks[key]
  );

  const isNetworkPartiallySelected = unselectedNetworks.length > 0;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        w={{ base: "48px", md: "188px" }}
        h={{ base: "48px", md: "56px" }}
        pos="fixed"
        bg="accent"
        borderRadius={{ base: "44px", md: "56px" }}
        bottom="16px"
        right={{ base: "8px", md: "16px" }}
        p={{ base: 0, md: "16px 24px" }}
        cursor="pointer"
        zIndex="5"
        gap="8px"
        onClick={() => {
          onOpen();
          trackEvent(LP_EMAIL_MODAL_OPEN);
        }}
      >
        <Text
          display={{ base: "none", md: "inline" }}
          fontWeight="600"
          fontSize="16px"
          lineHeight="24px"
          color="white"
        >
          Subscribe now
        </Text>

        <Image src="/assets/images/email-icon.svg" alt="" h="24px" w="24px" />
      </Flex>
      {isOpen && (
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement={isMobileOrTablet ? "bottom" : "right"}
          onClose={onClose}
        >
          <DrawerOverlay zIndex={1500} className="emailModalContent" />
          <DrawerContent maxW={773} bg="white">
            {!isMobileOrTablet && (
              <DrawerCloseButton left={10} top={10} boxShadow="none" />
            )}
            {isMobileOrTablet && (
              <Center {...handlers}>
                <Divider
                  my={4}
                  w="64px"
                  borderRadius={4}
                  borderWidth="4px"
                  borderColor="divider"
                />
              </Center>
            )}
            <DrawerBody
              mt={{ base: "36px", lg: "104px" }}
              // px={10}
              textAlign={{ base: "center", lg: "inherit" }}
            >
              <Text color="textPrimary" textStyle={{ base: "h3", lg: "h1" }}>
                Subscribe
              </Text>
              <Text
                color="textSecondary"
                mt="8px"
                mb="2rem"
                textStyle={{ base: "p12", lg: "p14" }}
                fontWeight="500 !important"
              >
                Get updates, exclusive offers and more delivered right to your
                inbox
              </Text>
              <form onSubmit={handleSubmit}>
                <InputGroup
                  size="lg"
                  mt="0.5rem"
                  mb="0"
                  w={{ base: "100%", lg: "656px" }}
                >
                  <Input
                    bg="canvas"
                    border="none"
                    type="email"
                    required
                    placeholder="Email address"
                    value={email}
                    fontSize="1rem"
                    h={{ base: "56px", xl: "58px" }}
                    pl={{ base: 4, lg: 8 }}
                    borderRadius="10px"
                    onChange={(e) => setEmail(e.target.value)}
                    _placeholder={{ color: "#1425284D" }}
                  />
                </InputGroup>
                {error && (
                  <Text textStyle="p14" color="#e53e3e" mt="0.5rem">
                    {error}
                  </Text>
                )}

                <Flex
                  mt="1rem"
                  flexDir={{ base: "column", xl: "row" }}
                  alignItems={isMobileOrTablet ? "center" : "flex-start"}
                  gap={1}
                  fontSize={{ base: 12, lg: 16 }}
                >
                  {isNetworkPartiallySelected ? (
                    <Center flexDir="column" gap={4}>
                      <Text color="brand.textInactive" mr="6px">
                        {selectedNetworkList.length}{" "}
                        {selectedNetworkList.length === 1
                          ? "network "
                          : "networks "}
                        selected
                      </Text>
                    </Center>
                  ) : (
                    <Flex
                      align="center"
                      flexDir={{ base: "column", lg: "row" }}
                      gap={2}
                    >
                      <Image
                        display={{ base: "none", lg: "inherit" }}
                        src="/assets/images/check.svg"
                        alt="check"
                      />
                      <Text color="brand.textInactive">
                        You are subscribing to all our networks
                      </Text>
                    </Flex>
                  )}
                </Flex>

                <NetworkOptions
                  networks={networks}
                  onSelectNetwork={(id) => handleNetworkSelect(id)}
                  selectedNetworks={selectedNetworks}
                />

                <Button
                  w="152px"
                  type="submit"
                  mt={{ base: 0, md: "1.5rem" }}
                  h={{ base: "48px", md: "56px" }}
                  p="10px 2.5rem"
                >
                  {isSubscribing ? (
                    <Spinner size="md" color="white" />
                  ) : (
                    <Text fontSize="16px" fontWeight="600">
                      Subscribe
                    </Text>
                  )}
                </Button>
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default EmailModal;
