import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { NavItemsType } from "constants/types";
import { trackEvent } from "utils/firebase";
import { LP_START_STAKING } from "constants/firebase";
import { iconMapping } from "constants/icons";

type Props = {
  buttonText: string;
  networks: NavItemsType["details"];
};

const StakeNow = ({ buttonText, networks }: Props) => {
  if (networks.length === 0) {
    return null;
  }

  if (networks.length === 1) {
    const data = networks[0];
    return (
      <Button
        as="a"
        href={data.primaryCTAUrl}
        target="_blank"
        rel="noreferrer"
        rightIcon={<Image src="/assets/images/arrow-right.svg" alt="arrow" />}
        onClick={() => {
          trackEvent(LP_START_STAKING, {
            type: "header",
            cta: data.label,
            link: data.primaryCTAUrl,
          });
        }}
      >
        {data.primaryCTAText}
      </Button>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={
          <Image src="/assets/images/chevron-down-white.svg" alt="arrow" />
        }
        p="10px 16px"
        iconSpacing={3}
        _active={{
          bg: "accent",
        }}
      >
        {buttonText}
      </MenuButton>
      <MenuList
        minW={145}
        bg="white"
        boxShadow="none"
        border="1px solid"
        borderColor="gray.200"
      >
        {networks
          .filter((network) => !network.isDisabled)
          .map((network) => (
            <MenuItem
              key={network.id}
              as="a"
              href={network.primaryCTAUrl}
              target="_blank"
              icon={
                <Image
                  src={iconMapping[network.imgKey]}
                  alt={network.imgKey}
                  boxSize={5}
                />
              }
              iconSpacing={2}
              isDisabled={network.isDisabled}
              onClick={() => {
                trackEvent(LP_START_STAKING, {
                  type: "header",
                  cta: network.label,
                  link: network.primaryCTAUrl,
                });
              }}
            >
              <Text as="span" fontWeight={600}>
                {network.label}
              </Text>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default StakeNow;
