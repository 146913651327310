import { createContext, ReactNode } from "react";
import navData from "@constants/navData.json";
import pageData from "@constants/pageData.json";
interface IData {
  page: {
    data?: any;
    error?: any;
    // networks: any[];
  };
}

const initialData: IData = {
  page: {
    data: { navData, pageData },
    error: null,
    // networks: [],
  },
};

export const PageContext = createContext(initialData);

interface IProps extends IData {
  children: ReactNode;
}

export const PageProvider = ({ page = initialData.page, children }: IProps) => {
  return (
    <PageContext.Provider value={{ page }}>{children}</PageContext.Provider>
  );
};
