type Mapping = {
  [x: string]: any;
};

export const iconMapping: Mapping = {
  stader: "/assets/images/stader-icon.svg",
  polygon: "/assets/images/networks/polygon.svg",
  bnb: "/assets/images/networks/binance.svg",
  hedera: "/assets/images/networks/hedera.svg",
  near: "/assets/images/networks/near.svg",
  terra: "/assets/images/networks/terra.svg",
  fantom: "/assets/images/networks/fantom.svg",
  eth: "/assets/images/networks/ethereum.svg",
  telegram: "/assets/images/social/telegram.svg",
  twitter: "/assets/images/social/twitter.svg",
  discord: "/assets/images/social/discord.svg",
  reddit: "/assets/images/social/reddit.svg",
  community: "/assets/images/community-forum.svg",
  snapshot: "/assets/images/snapshot.svg",
  sd: "/assets/images/sd.svg",
  github: "/assets/images/github.svg",
  litepaper: "/assets/images/litepaper.svg",
  docs: "/assets/images/docs.svg",
  blogs: "/assets/images/blogs.svg",
  download: "/assets/images/download.svg",
  pantera: "/assets/images/partners/institutions/pantera.svg",
  coinbase: "/assets/images/partners/institutions/coinbase-ventures.svg",
  jump: "/assets/images/partners/institutions/jump-capital.svg",
  blockchain: "/assets/images/partners/institutions/blockchain-com.svg",
  true: "/assets/images/partners/institutions/true-ventures.svg",
  ledger: "/assets/images/partners/ecosystems/ledger.svg",
  aave: "/assets/images/partners/ecosystems/aave.svg",
  balancer: "/assets/images/partners/ecosystems/balancer.svg",
  beefy: "/assets/images/partners/ecosystems/beefy.svg",
  swissborg: "/assets/images/partners/ecosystems/swissborg.svg",
  quickswap: "/assets/images/partners/ecosystems/quickswap.svg",
  okx: "/assets/images/partners/exchanges/okx.svg",
  gate: "/assets/images/partners/exchanges/gate-io.svg",
  huobi: "/assets/images/partners/exchanges/huobi.svg",
  bybit: "/assets/images/partners/exchanges/bybit.svg",
  kucoin: "/assets/images/partners/exchanges/kucoin.svg",
  crypto: "/assets/images/partners/exchanges/crypto-com.svg",
};

export const iconHoveredMapping: Mapping = {
  stader: "/assets/images/stader-icon-white.svg",
  polygon: "/assets/images/networks/polygon-white.svg",
  bnb: "/assets/images/networks/binance-white.svg",
  hedera: "/assets/images/networks/hedera-white.svg",
  near: "/assets/images/networks/near-white.svg",
  terra: "/assets/images/networks/terra-white.svg",
  fantom: "/assets/images/networks/fantom-white.svg",
  eth: "/assets/images/networks/ethereum.svg",
  telegram: "/assets/images/social/telegram.svg",
  twitter: "/assets/images/social/twitter.svg",
  discord: "/assets/images/social/discord.svg",
  community: "/assets/images/community-forum.svg",
  snapshot: "/assets/images/snapshot.svg",
  sd: "/assets/images/sd.svg",
  github: "/assets/images/github-white.svg",
  litepaper: "/assets/images/litepaper-white.svg",
  docs: "/assets/images/docs-white.svg",
  blogs: "/assets/images/blogs-white.svg",
  download: "/assets/images/download-white.svg",
};
