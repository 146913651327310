import React, { useEffect, useState } from "react";
import NextLink from "next/link";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";

// Components
import Column from "./components/Column";
import EmailModal from "./components/EmailModal";
import Section from "../Section";

// Constants
import { HeaderDataType } from "constants/types";
import { iconMapping } from "constants/icons";
import { LP_SOCIAL, LP_TOS_CLICK } from "constants/firebase";

import { trackEvent } from "utils/firebase";
import { isSubscribedToEmail } from "@utils/common";

type FooterProps = {
  data: HeaderDataType;
  isEmailModalRequired?: boolean;
};

const Footer = ({ data, isEmailModalRequired = true }: FooterProps) => {
  const { navItems, socials, brand, icon_key, copyrightText, footerLinks } =
    data;
  const [isUserSubscribed, setIsUserSubscribed] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsUserSubscribed(isSubscribedToEmail());
    }
  }, []);

  useEffect(() => {
    function checkIfUserSubscribed() {
      if (typeof window !== "undefined") {
        setIsUserSubscribed(isSubscribedToEmail());
        return;
      }
      if (!isUserSubscribed) {
        setIsUserSubscribed(false);
      }
    }

    window.addEventListener("storage", checkIfUserSubscribed);

    return () => {
      window.removeEventListener("storage", checkIfUserSubscribed);
    };
  }, []);

  return (
    <Box bg="#142528" w="full" color="white">
      <Section py={{ base: "40px", lg: "56px" }}>
        {!isUserSubscribed && isEmailModalRequired ? <EmailModal /> : null}
        <Flex
          w="full"
          mt={{ base: 0, lg: 12 }}
          justify="space-between"
          flexDir={{ base: "column", lg: "row" }}
          px={{ base: 4, lg: 0 }}
        >
          <Box>
            <Flex align="center" ml={{ base: 0, lg: -2 }}>
              <Image
                src={iconMapping[icon_key]}
                alt="stader-icon"
                boxSize={10}
                ml={-2}
              />
              <Text fontSize={{ base: 22, lg: 28 }} fontWeight={600}>
                {brand}
              </Text>
            </Flex>
            <HStack mt={6} align="center" gap={2}>
              {socials.map(({ imgKey, link }) => (
                <React.Fragment key={link}>
                  <Link
                    href={link}
                    rel="canonical"
                    isExternal
                    onClick={() =>
                      trackEvent(LP_SOCIAL, {
                        social_cta: "footer",
                        social_name: imgKey,
                      })
                    }
                    borderRadius="50%"
                    border="1px solid"
                    borderColor="#142528"
                    _hover={{
                      borderColor: "accent",
                    }}
                  >
                    <Image
                      boxSize={{ base: 8, lg: 10 }}
                      src={iconMapping[imgKey]}
                      alt={imgKey}
                    />
                  </Link>
                </React.Fragment>
              ))}
            </HStack>
          </Box>
          <Flex
            mt={{ base: 10, lg: 0 }}
            flexDir={{ base: "column", lg: "row" }}
            gap={10}
            flexWrap="wrap"
            w={{ base: "auto", lg: 812 }}
          >
            {navItems.map(({ title, details }) => (
              <Column key={`footer-${title}`} label={title} items={details} />
            ))}
          </Flex>
        </Flex>
        <Flex
          justify="space-between"
          flexDir={{ base: "column", lg: "row" }}
          align={{ base: "flex-start", lg: "center" }}
          pt={8}
          px={{ base: 4, lg: 0 }}
          fontSize={{ base: 14, lg: 16 }}
          gap={4}
          opacity={0.7}
          mb={!isUserSubscribed && isEmailModalRequired ? "1rem" : 0}
        >
          <Text>{copyrightText}</Text>
          <Flex gap="0.5rem" alignItems="center">
            {footerLinks.links.map((link, index) => (
              <>
                <NextLink href={link.link} key={link.key}>
                  <Text
                    cursor="pointer"
                    onClick={() => {
                      trackEvent(LP_TOS_CLICK, {
                        cta: link.title,
                        link: link,
                      });
                    }}
                  >
                    {link.title}
                  </Text>
                </NextLink>
                {index !== footerLinks.links.length - 1 && (
                  <Divider
                    orientation="vertical"
                    borderWidth="1px"
                    height={{ base: "12px", lg: "14px" }}
                  />
                )}
              </>
            ))}
          </Flex>
        </Flex>
      </Section>
    </Box>
  );
};

export default Footer;
