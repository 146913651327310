import { Box, Center, Image, Link, Tag, Text } from "@chakra-ui/react";
import { isExternalLink } from "utils/common";
import { EVENT_MAPPINGS } from "constants/firebase";
import { trackEvent } from "utils/firebase";
import NextLink from "next/link";
interface Props {
  title: string;
  label: string;
  icon?: string;
  iconHovered?: string;
  tag?: string;
  link: string;
  isDisabled: boolean;
  isDownloadable: boolean;
}

const OptionCard = ({
  title,
  label,
  icon,
  iconHovered,
  tag,
  link,
  isDisabled,
  isDownloadable,
}: Props) => (
  <Link
    as={isExternalLink(link) ? "a" : NextLink}
    href={link || ""}
    rel="canonical"
    target={isExternalLink(link) ? "_blank" : "_self"}
    pointerEvents={isDisabled ? "none" : "all"}
    download={isDownloadable}
  >
    <Box
      w={176}
      h={222}
      p={4}
      bg="#E6F8EF"
      borderRadius={16}
      color="textPrimary"
      cursor="pointer"
      transition="all 0.3s ease"
      textAlign="center"
      _focus={{
        outline: "none",
      }}
      _hover={{
        bg: "radial-gradient(122.38% 69.59% at 50.3% 100%, #07C166 0%, #18C972 0.01%, #07A658 100%)",
        boxShadow: "0px 0px 32px rgba(20, 37, 40, 0.4)",
        color: "white",
      }}
      sx={{
        "&:hover .tag": {
          bg: "accent",
        },
        "&:hover img": {
          content: `url(${iconHovered})`,
        },
      }}
      onClick={() => {
        trackEvent(EVENT_MAPPINGS[title.toLowerCase()] || title, {
          cta: "header",
          name: label,
        });
      }}
    >
      <Center h="full" w="full" flexDir="column" gap={4}>
        {icon && <Image src={icon} alt="github" boxSize={16} />}
        <Text fontSize={20} fontWeight={600}>
          {label}
        </Text>
        {tag && (
          <Tag
            className="tag"
            bg="notification"
            py={1}
            px={2}
            textTransform="uppercase"
            color="white"
          >
            <Text textStyle="l12">{tag}</Text>
          </Tag>
        )}
      </Center>
    </Box>
  </Link>
);

export default OptionCard;
