import { Flex, Box, Image, Text } from "@chakra-ui/react";
import { iconMapping, iconHoveredMapping } from "@constants/icons";
import { NavItemsType } from "@constants/types";
import { IIndexable } from "types/common";

interface NetworkOptionsProps {
  networks: NavItemsType["details"];
  selectedNetworks: IIndexable;
  onSelectNetwork: (id: string) => any;
}

const NetworkOptions = ({
  networks,
  selectedNetworks,
  onSelectNetwork,
}: NetworkOptionsProps) => {
  const handleNetworkSelect = (id: string) => {
    onSelectNetwork(id);
  };

  return (
    <Flex
      my={10}
      flexWrap="wrap"
      align="center"
      justify={{ base: "center", lg: "inherit" }}
      gap={{ base: 2, lg: 3 }}
    >
      {networks.map(({ detailId, label, imgKey }) => {
        const isActive = selectedNetworks[detailId];

        return (
          <Flex
            align="center"
            key={detailId}
            gap={2}
            p={{ base: 1, lg: 4 }}
            pr={{ base: 3, lg: 4 }}
            h={{ base: "40px", lg: "56px" }}
            border="1px solid"
            bg={isActive ? "#E6F8EF" : "transparent"}
            borderColor={isActive ? "accent" : "divider"}
            borderRadius={{ base: "40px", lg: "56px" }}
            cursor="pointer"
            onClick={() => handleNetworkSelect(detailId)}
          >
            <Box
              border="1px solid"
              borderColor={isActive ? "transparent" : "textSecondary"}
              borderRadius="50%"
            >
              <Image
                boxSize={{ base: 6, lg: 8 }}
                src={
                  isActive ? iconMapping[imgKey] : iconHoveredMapping[imgKey]
                }
                alt="label"
              />
            </Box>
            <Text
              fontSize={{ base: 12, lg: 16 }}
              fontWeight={isActive ? 600 : 500}
              color={isActive ? "primary" : "inherit"}
            >
              {label}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default NetworkOptions;
