import { useEffect } from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";

import { LP_BANNER_CTA_CLICK, LP_VIEW_BANNER } from "@constants/firebase";

import { trackEvent } from "@utils/firebase";

type Props = {
  open: boolean;
  close: () => void;
  text: string;
  ctaText: string;
  ctaLink: string;
};

const Banner = ({ open, close, text, ctaText, ctaLink }: Props) => {
  useEffect(() => {
    trackEvent(LP_VIEW_BANNER);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Box
      w="100vw"
      py="10px"
      px={5}
      pr={10}
      bg="notification"
      color="white"
      pos="relative"
      zIndex={1500}
    >
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        align={{ base: "flex-start", lg: "center" }}
        justify="center"
        gap={3}
      >
        <Text
          textStyle={{ base: "p12", lg: "p14" }}
          fontWeight="600 !important"
        >
          {text}
        </Text>
        <Link
          isExternal
          href={ctaLink}
          onClick={() =>
            trackEvent(LP_BANNER_CTA_CLICK, {
              cta_text: ctaText,
              cta_link: ctaLink,
            })
          }
        >
          <Button
            size="sm"
            bg="white !important"
            color="textPrimary  !important"
            _hover={{ bg: "white", color: "textPrimary" }}
            fontSize={14}
            fontWeight={600}
            h={7}
          >
            {ctaText}
          </Button>
        </Link>
      </Flex>
      <Box
        pos="absolute"
        top={17}
        right={18}
        float="right"
        onClick={close}
        cursor="pointer"
      >
        <Image src="/assets/images/cross.svg" alt="close" />
      </Box>
    </Box>
  );
};

export default Banner;
